import React, { useMemo } from "react"
import getDate from "../helpers/getDate"
import getReadTime from "../helpers/getReadTime"
import Link from "./Link"
import { GatsbyImage } from "gatsby-plugin-image";

export const BlogCard = ({ lang, blog, key }) => {


  const allTextContent = useMemo(() => {
    let allTextContent = '';
    blog?.content?.filter(item => item.id.startsWith('DatoCmsContentSection')).forEach(section => {
      allTextContent += section.content;
    });
    return allTextContent;
  }, [blog])


  return (
    <div className="blog-card__container" key={key}>
      <div className="blog-card__image-container">
        <Link
          to={`/blog/${blog.slug}/`}
          lang={lang}
        >
          <GatsbyImage
            image={blog.image.gatsbyImageData}
            alt={blog.image.alt}
            width
            className="blog-card__image" />
        </Link>
      </div>
      <div className="blog-card__content">
        <div className="blog-card__tags">
          {blog.categories.map((category) => (
            <div className="blog-card__tag-item" key={category.id}>
              {category.name}
            </div>
          ))}
        </div>
        <h3 className="blog-card__title">
          <Link
            to={`/blog/${blog.slug}/`}
            lang={lang}
          >
            {blog.title}
          </Link>
        </h3>

        <div className="blog-card__more">
          <div className="about">
            <span className="date">{`${getDate(
              blog.articleDate,
              lang
            )} ·`}</span>
            <span className="read">
              {` ${getReadTime(allTextContent)} ${lang === "de" ? "min lesezeit" : "min read"
                }`}
            </span>
          </div>
          <div className="link">
            <Link
              to={`/blog/${blog.slug}/`}
              className="btn btn__secondary"
              lang={lang}
            >
              {`${lang === "en" ? "Read" : "Lesen"} >`}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

const getDate = (date, locale) => {

    // 2020-12-01 we get date like this from cms
    // 06 November 2020 expected return
    // month always have 2 digit - 01, 02, 03 ...

    const enMonths = [ 'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December' ];
    const deMonths = [ 'Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember' ];

    const splitDate = date.split('-'), year = splitDate[0], month = splitDate[1], day = splitDate[2];
    const checkMonth = month.charAt(0) === '0' ? month.substring(1) : month; // 03 - return 3
    const getMonth = locale === 'en' ? enMonths[parseInt(checkMonth) - 1] : deMonths[parseInt(checkMonth) - 1];

    return `${day} ${getMonth} ${year}`;
}

export default getDate;
const generateBlogDetails = (headline, image, wordcount, url, articleDate, description, body, authorName, authorUrl) => {
    return JSON.stringify(`
    {
        "@context": "https://schema.org",
        "@type": "BlogPosting",
        "headline": "${headline}",
        "image": "${image}",
        "wordcount": "${wordcount}",
        "publisher": {
            "@context": "http://schema.org",
            "@type": "Organization",
            "name": "Valudio",
            "url": "https://www.valudio.com/"
          },
        "url": "${url}/",
        "datePublished": "${articleDate}",
        "dateCreated": "${articleDate}",
        "dateModified": "${articleDate}",
        "description": "${description}",
        "articleBody": "${body}",
        "author": {
            "@type": "Person",
            "name": "${authorName}",
            "url": "${authorUrl}"
        }
    }
    `)
}

export default generateBlogDetails;
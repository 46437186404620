import React from "react"
import { GatsbyImage } from "gatsby-plugin-image";
import useWindowWidth from "../../../helpers/useWindowWidth"

import { QuoteIconTop, QuoteIconBottom } from "../../Icons"



export const QuoteBlogSection = ({ quoteData }) => {
  const { authorImage, author, quoteContent, authorRole } = quoteData;
  const window = useWindowWidth()

  return (
    <section className={`quote-blog ${authorImage ? "isAuthorImage" : ""}`}>
      {authorImage && (
        <div className="quote-blog__left">
          <GatsbyImage
            className="quote-blog__author-image"
            image={authorImage.gatsbyImageData}
            alt={authorImage.alt}
            title={authorImage.alt}
          />
          <p className="quote-blog__author">{author}</p>
          <p className="quote-blog__author-role">{authorRole}</p>
        </div>)}
      <div className={`quote-blog__right ${authorImage ? "isAuthorImage" : ""}`}>
        <p className={`quote-blog__right--text ${authorImage ? "isAuthorImage" : ""}`}>
          <QuoteIconTop />
          {quoteContent}
          <QuoteIconBottom />
        </p>
        {!authorImage && <div className="quote-blog__author--content">
          <p className="quote-blog__author">{author}</p>
          <p className="quote-blog__author-role">{authorRole}</p>
        </div>}
      </div>

    </section >
  )
}

import React from "react"
import Link from '../../Link';
import CtaBg from "../../../img/cta-blog-bg.png"


export const CTABlogSection = ({ ctaData, lang }) => {
  const { ctaText, buttonName, buttonLink } = ctaData;
  return (
    <section className="cta-blog"
      style={{
        backgroundImage: `url(${CtaBg})`,
      }}>
      <div className="cta-blog__text" dangerouslySetInnerHTML={{ __html: ctaText }} />
      <div className="contact__teaser--cta">
        <Link lang={lang} className="btn btn__primary" to={buttonLink}>
          {buttonName}
        </Link>
      </div>
    </section >
  )
}

import React from "react"

export const SummaryBlogSection = ({ summaryData,lang }) => {
  return (
    <section id={lang === 'en'?'Summary':'Überblick'} className="summary-blog">
      <h3 className="summary-blog__title" ><span>{lang === 'en'?'Summary':'Überblick'}</span> </h3>
      {summaryData[0].items.map((item, index) => (
        <div className="summary-blog__item" key={index}>
          <h4 className="summary-blog__item--title">{item.title}</h4>
          <p>{item.description}</p>
        </div>
      ))}
    </section >
  )
}
